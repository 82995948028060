import { Notifications, Person, Edit } from '@mui/icons-material';
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Slide,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { HeaderSettings } from '../../constants/HeaderMenu';
import AuthContext from '../../contexts/AuthContext';
import { UIContext } from '../../contexts/UIProvider';
import Notification from '../Notification';

import './Header.css';
import { fetchUserNotificationMessages } from '../../api';
import UpdatedAcceptPolicies from './UpdatedAcceptPolicies';
import UnreadMessageCounts from '../../pages/Messaging/UnreadCount';
import { DataContext } from '../../contexts/DataProvider';
import { FirestoreDB, LogAnalyticsEvent } from '../../utils/firebase/firebase';
import { ANALYTICS_KEYS } from '../../constants/AnalyticKeys';
import { collection, getDocs, onSnapshot } from 'firebase/firestore';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const Header = ({ onclick }) => {
  const [anchorElUser, setAnchorElUser] = useState(null);

  const [notificationCount, setnotificationCount] = useState(0);

  const [notifications, setNotifications] = useState([]);

  const [title, setTitle] = useState('');

  const [open, setOpen] = useState(false);
  const [unreadCounts, setUnreadCounts] = useState([]);

  const [currentStep, setCurrentStep] = useState(1);

  const {
    user,
    typeOfUser,
    logout,
    setOpenRightPanel,
    setPanelContent,
    setIsNotificationPanel,
  } = useContext(AuthContext);
  const {
    updateSelectedMenu,
    setSelectedMenuKey,
    selectedMenuKey,
    isDrawerOpen,
    setIsDrawerOpen,
    isSmallScreen,
    selectPatient,
  } = useContext(UIContext);
  const { patientData } = useContext(DataContext);
  const [patientsLoaded, setPatientsLoaded] = useState(false);
  const [isUserExists, setIsUserExists] = useState(false);

  const location = useLocation();

  const doctorListCount = async () => {
    const doctorsRef = collection(FirestoreDB, 'doctorsList');
    return onSnapshot(
      doctorsRef,
      (querySnapshot) => {
        const doctorCount = querySnapshot.docs.length;

        // Log a custom event to Firebase Analytics for successfully fetching doctor data
        LogAnalyticsEvent(ANALYTICS_KEYS.COUNT.DOCTORSCOUNT, {
          doctorCount: doctorCount, // The number of doctors fetched
          message: `${doctorCount} doctors successfully fetched`,
        });
      },
      (error) => {
        console.log('Error listening to doctorsList:', error);
        // Log a custom event to Firebase Analytics for an error during fetch
        LogAnalyticsEvent('doctor_list_fetch_error', {
          errorMessage: error.message, // Capture error message
          message: `Error fetching doctor list: ${error.message}`,
        });
      }
    );
  };

  const patientListCount = async () => {
    const patientsRef = collection(FirestoreDB, 'patientsList');
    return onSnapshot(
      patientsRef,
      (querySnapshot) => {
        const patientCount = querySnapshot.docs.length;

        // Log a custom event to Firebase Analytics for successfully fetching patient data
        LogAnalyticsEvent(ANALYTICS_KEYS.COUNT.PATIENTSCOUNT, {
          patientCount: patientCount, // The number of patients fetched
          message: `${patientCount} patients successfully fetched`,
        });
      },
      (error) => {
        console.log('Error listening to patientsList:', error);
        // Log a custom event to Firebase Analytics for an error during fetch
        LogAnalyticsEvent('patient_list_fetch_error', {
          errorMessage: error.message, // Capture error message
          message: `Error fetching patient list: ${error.message}`,
        });
      }
    );
  };

  useEffect(() => {
    const videoUploadCount = () => {
      const videosRef = collection(FirestoreDB, 'videoLogs');

      // Return the unsubscribe function directly from onSnapshot
      const unsubscribe = onSnapshot(
        videosRef,
        async (querySnapshot) => {
          let totalVideoCount = 0;

          try {
            // Iterate through each user's videoLogs collection
            for (const doc of querySnapshot.docs) {
              const userRawVideosRef = collection(doc.ref, 'rawVideos');
              // Fetch the rawVideos for each user and count them
              const userRawVideosSnapshot = await getDocs(userRawVideosRef);
              totalVideoCount += userRawVideosSnapshot.size;
            }

            // Log analytics event for successful video data fetch
            LogAnalyticsEvent(ANALYTICS_KEYS.COUNT.VIDEOSCOUNT, {
              totalVideoCount: totalVideoCount,
              message: `Successfully fetched ${totalVideoCount} videos across all users.`,
            });
          } catch (error) {
            console.error('Error fetching videos:', error);

            // Log analytics event for error during video fetching
            LogAnalyticsEvent('video_upload_fetch_error', {
              errorMessage: error.message,
              message: `Error fetching video data: ${error.message}`,
            });
          }
        },
        (error) => {
          console.error('Error listening to videoLogs:', error);

          // Log analytics event for error listening to videoLogs
          LogAnalyticsEvent('video_upload_listener_error', {
            errorMessage: error.message,
            message: `Error listening to videoLogs: ${error.message}`,
          });
        }
      );

      return unsubscribe; // Return the unsubscribe function directly
    };

    // Call the videoUpload function to set up the onSnapshot listener
    const unsubscribe = videoUploadCount();

    // Cleanup the onSnapshot listener when the component unmounts
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    const documentUploadCount = () => {
      const documentsRef = collection(FirestoreDB, 'historicalData');

      // Return the unsubscribe function directly from onSnapshot
      const unsubscribe = onSnapshot(
        documentsRef,
        async (querySnapshot) => {
          let totaldocumentCount = 0;

          try {
            for (const doc of querySnapshot.docs) {
              const userRawdocumentsRef = collection(doc.ref, 'attachments');

              const userRawdocumentsSnapshot = await getDocs(
                userRawdocumentsRef
              );
              totaldocumentCount += userRawdocumentsSnapshot.size;
            }

            LogAnalyticsEvent(ANALYTICS_KEYS.COUNT.DOCUMENTSCOUNT, {
              totaldocumentCount: totaldocumentCount,
              message: `Successfully fetched ${totaldocumentCount} documents across all users.`,
            });
          } catch (error) {
            console.error('Error fetching documents:', error);

            LogAnalyticsEvent('document_upload_fetch_error', {
              errorMessage: error.message,
              message: `Error fetching document data: ${error.message}`,
            });
          }
        },
        (error) => {
          console.error('Error listening to documentLogs:', error);

          // Log analytics event for error listening to documentLogs
          LogAnalyticsEvent('document_upload_listener_error', {
            errorMessage: error.message,
            message: `Error listening to documentLogs: ${error.message}`,
          });
        }
      );

      return unsubscribe; // Return the unsubscribe function directly
    };

    const documentsTotolCount = documentUploadCount();

    // Cleanup the onSnapshot listener when the component unmounts
    return () => {
      if (documentsTotolCount) {
        documentsTotolCount();
      }
    };
  }, []);

  useEffect(() => {
    doctorListCount();
    patientListCount();
  }, []);

  const formatName = (name) => {
    return name
      .split(/\s+/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };
  React.useEffect(() => {
    let key = localStorage.getItem('selectedMenuKey') || selectedMenuKey;
    if (
      localStorage.getItem('selectedPatientId') !== '-1' &&
      selectedMenuKey !== 'Home'
    ) {
      setTitle(`Patient Report`);
    } else if (key === 'Home') {
      const formattedUserName = user?.fullname || user?.displayName || 'User';
      const formattedTitle = formatName(formattedUserName);
      setTitle(`Welcome, ${formattedTitle}`);
    } else if (key === 'Appointments') {
      setTitle('Appointments');
    } else if (key === 'Statistics') {
      setTitle('Statistics');
    } else if (key === 'HistoricalData') {
      setTitle('Historical Data');
    } else if (key === 'Settings') {
      setTitle(`Settings`);
    } else if (key === 'Privacy') {
      setTitle('Privacy Statement');
    } else if (key === 'Termcondition') {
      setTitle('Term and Conditions');
    } else if (key === 'Contact') {
      setTitle('Contact Information');
    } else if (key === 'Userguide') {
      setTitle('User Guide');
    } else if (key === 'Messaging') {
      setTitle('Messaging');
    }
    else if (selectedMenuKey === "Messaging") {
      setTitle("Messaging");
    }
    else if (selectedMenuKey === "Messaging") {
      setTitle("Messaging");
    }
  }, [selectedMenuKey, user]);

  useEffect(() => {
    if (user) {
      fetchNotifications();
      setIsUserExists(true);
    }
  }, [user]);

  useEffect(() => {
    patientData?.length > 0 && setPatientsLoaded(true);
  }, [patientData]);

  useEffect(() => {
    if (location.pathname === '/') {
      updateSelectedMenu('Home');
    }
  }, [location]);

  const navigate = useNavigate();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const settingClickHandler = (setting) => {
    handleCloseUserMenu();
    if (setting.key === 'profile') {
      navigate('/settings');
      updateSelectedMenu('Settings');
    }
    if (setting.key === 'dashboard') {
      navigate('/');
      updateSelectedMenu('Home');
    }
    if (setting.key === 'logout') {
      setSelectedMenuKey('Home');

      const logoutKey =
        typeOfUser === 'DOCTOR'
          ? ANALYTICS_KEYS.USERS.DOCTORLOGOUT
          : ANALYTICS_KEYS.USERS.PATIENTLOGOUT;

      const userType = typeOfUser ? typeOfUser.toLowerCase() : 'unknown';

      LogAnalyticsEvent(logoutKey, {
        userUid: user.uid,
        message: `${userType} successfully logged out`,
      });
      logout();
      navigate('/main');
    }
  };

  const fetchNotifications = async () => {
    const messages = await fetchUserNotificationMessages(user?.uid);

    const filteredMessages = messages.filter(
      (message) =>
        message.title !== 'Patient has sent you a message' &&
        message.title !== 'Healthcare Professional has sent you a message'
    );

    const unreadMessages = filteredMessages.filter(
      (message) => !message.readStatus && message.title
    );

    setNotifications(messages);

    setnotificationCount(unreadMessages?.length);
  };

  const handleShowNotifications = () => {
    setPanelContent(
      <Notification
        setnotificationCount={setnotificationCount}
        notifications={notifications}
        fetchNotifications={fetchNotifications}
        setOpenRightPanel={setOpenRightPanel}
      />
    );
    setOpenRightPanel(true);
    setIsNotificationPanel(true);
  };

  const renderUnreadMessageCounts = () => {
    if (typeOfUser === 'DOCTOR') {
      if (patientsLoaded && isUserExists) {
        return (
          <UnreadMessageCounts
            unreadCounts={unreadCounts}
            setUnreadCounts={setUnreadCounts}
            user={user}
            typeOfUser={typeOfUser}
            patientData={patientData}
          />
        );
      }
    } else if (typeOfUser === 'PATIENT') {
      if (isUserExists) {
        <UnreadMessageCounts
          unreadCounts={unreadCounts}
          setUnreadCounts={setUnreadCounts}
          user={user}
          typeOfUser={typeOfUser}
        />;
      }
    }
    return null;
  };

  return (
    <>
      {typeOfUser !== 'ADMIN' && <UpdatedAcceptPolicies />}
      <Toolbar disableGutters className="header">
        {isSmallScreen && (
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Menu">
              <IconButton onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
                <MenuIcon sx={{ color: 'white' }} />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="h5"
            component="div"
            sx={{
              ml: 2,
              flexGrow: 1,
              fontFamily: 'Lato',
              color: 'white',
            }}
          >
            {title}
          </Typography>
        </Box>

        <Box className="flexBox" sx={{ flexGrow: 0 }}>
          <Tooltip title="Edit your profile">
            <IconButton
              component={Link}
              to="/settings"
              onClick={() => {
                updateSelectedMenu('Settings');
              }}
            >
              <Edit sx={{ color: 'white' }} />
            </IconButton>
          </Tooltip>

          {!typeOfUser === 'ADMIN' && (
            <Tooltip title="Notifications">
              <IconButton onClick={handleShowNotifications} sx={{ mr: 2 }}>
                <Badge
                  color="secondary"
                  overlap="circular"
                  badgeContent={
                    notificationCount > 0 ? notificationCount : null
                  }
                >
                  <Notifications sx={{ color: 'white' }} />
                </Badge>
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar src={user?.photoURL}>
                <Person />
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem key={'welcome'} onClick={handleCloseUserMenu} disabled>
              <Typography textAlign="center">
                Welcome,{' '}
                {formatName(user?.fullname || user?.displayName || 'User')}
              </Typography>
            </MenuItem>

            {HeaderSettings.filter(
              (setting) =>
                !(typeOfUser === 'ADMIN' && setting.key === 'dashboard')
            ).map((setting) => (
              <MenuItem
                key={setting.key}
                onClick={() => settingClickHandler(setting)}
              >
                <Typography textAlign="center">{setting.text}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
      {renderUnreadMessageCounts()}

      {/* <UnreadMessageCounts
        unreadCounts={unreadCounts}
        setUnreadCounts={setUnreadCounts}
        user={user}
        typeOfUser={typeOfUser}
        patientData={patients}
      /> */}
    </>
  );
};

export default Header;