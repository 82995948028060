import React, { useEffect, useState } from 'react';
import MainLayout from '../../hoc/MainLayout';
import { Card, CardContent } from '@mui/material';
import { collection, getDocs, limit, orderBy, query } from 'firebase/firestore';
import { FirestoreDB } from '../../utils/firebase/firebase';
const TermsConditions = () => {
  const [termsText, setTermsText] = useState('');

  useEffect(() => {
    const fetchLatestTexts = async () => {
      try {
        const termsQuery = query(
          collection(FirestoreDB, 'terms_conditions'),
          orderBy('modified_date', 'desc'),
          limit(1)
        );

        const termsSnapshot = await getDocs(termsQuery);

        const latestTermsDoc = termsSnapshot.docs[0]?.data().terms || '';
        const decodedTermsText = atob(latestTermsDoc);

        setTermsText(decodedTermsText);
      } catch (error) {
        console.error('Error fetching latest data: ', error);
      }
    };

    fetchLatestTexts();
  }, []);

  return (
    <MainLayout>
      <Card sx={{ maxWidth: '90%', margin: '20px auto', padding: 2 }}>
        <CardContent>
          <div
            className="post__description"
            dangerouslySetInnerHTML={{ __html: termsText }}
          />
        </CardContent>
      </Card>
    </MainLayout>
  );
};
export default TermsConditions;
