import { React, useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import HomeCard from '../../components/HomeCard';
import { Button, Box, Typography } from '@mui/material';
import MainLayout from '../../hoc/MainLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  addDoc,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  Timestamp,
} from 'firebase/firestore';
import { FirestoreDB } from '../../utils/firebase/firebase';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EditPolicy = () => {
  const location = useLocation();
  const { pageName, pageContent } = location.state || {};
  const [content, setContent] = useState(pageContent);
  const [version, setVersion] = useState('1.0');

  const navigate = useNavigate();

  const handleSubmit = () => {
    const newVersion = incrementVersion();
    addTermsToFirebase(newVersion);
  };

  const fetchLatestVersion = async () => {
    try {
      const collectionRef =
        pageName === 'Terms and Conditions'
          ? collection(FirestoreDB, 'terms_conditions')
          : collection(FirestoreDB, 'privacy_policies');

      const versionQuery = query(
        collectionRef,
        orderBy('modified_date', 'desc'),
        limit(1)
      );
      const querySnapshot = await getDocs(versionQuery);

      if (!querySnapshot.empty) {
        const latestDoc = querySnapshot.docs[0];
        const latestVersion = latestDoc.data().version;
        setVersion(latestVersion);
      }
    } catch (error) {
      console.error('Error fetching the latest version: ', error);
    }
  };

  useEffect(() => {
    fetchLatestVersion();
  }, []);

  const incrementVersion = () => {
    const currentVersion = parseFloat(version);
    const newVersion = (currentVersion + 0.1).toFixed(1);
    setVersion(newVersion);
    return newVersion;
  };

  const showToastMessage = (status) => {
    if (status === 'success') {
      toast.success('Your upload was successful', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.error('Your upload was Failed', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const addTermsToFirebase = async (newVersion) => {
    try {
      const collectionRef =
        pageName === 'Terms and Conditions'
          ? collection(FirestoreDB, 'terms_conditions')
          : collection(FirestoreDB, 'privacy_policies');

      // Convert content to base64
      const utf8Encoder = new TextEncoder();
      const jsonString =
        typeof content === 'string' ? content : JSON.stringify(content);
      const base64Content = btoa(
        String.fromCharCode(...new Uint8Array(utf8Encoder.encode(jsonString)))
      );

      const dataToSave = {
        modified_date: Timestamp.now(),
        [pageName === 'Terms and Conditions' ? 'terms' : 'policy']:
          base64Content,
        version: newVersion,
      };
      await addDoc(collectionRef, dataToSave);
      console.log('Policy or terms added successfully');
      showToastMessage('success');
      setTimeout(() => {
        handleClose();
      }, 4000); 
    } catch (error) {
      console.error('Error adding policy or terms: ', error);
      showToastMessage('error');
    }
  };

  const handleClose = () => {
    navigate('/admin/terms');
  };

  return (
    <MainLayout>
      <Box className="policiesPageOverviewContainer">
        <Box className="policies-area">
          <HomeCard customClass="policiesPageOVerviewCard">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontFamily: 'Lato',
                  fontWeight: 700,
                  color: '#9291F0',
                }}
                gutterBottom
              >
                Editing {pageName}
              </Typography>
            </div>
            <div className="quill-editor">
              <ReactQuill theme="snow" value={content} onChange={setContent} />
              {/* <div className="post__description" dangerouslySetInnerHTML={{ __html: content}}  /> */}
            </div>
            <Box className="buttons-area">
              <Box className="policiesOverviewCard" width="50%">
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="stretch"
                  gap="15px"
                  padding="15px"
                >
                  {/* <Button
                    fullWidth
                    variant="contained"
                    color="success"
                    onClick={handleSubmit}
                    sx={{
                      backgroundColor: '#9291f0',
                      textTransform: 'capitalize',
                      fontFamily: 'Lato',
                      fontWeight: 400,
                      fontSize: '1.2rem',
                      width: '100%',
                    }}
                  >
                    Save Draft
                  </Button> */}
                  <Button
                    variant="outlined"
                    onClick={handleClose}
                    sx={{
                      width: '48%',
                      fontFamily: 'Lato',
                      textTransform: 'capitalize',
                      color: 'var(--clr-theme-purple)',
                      borderColor: 'var(--clr-theme-purple)',
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    color="success"
                    onClick={handleSubmit}
                    sx={{
                      backgroundColor: '#9291f0',
                      textTransform: 'capitalize',
                      fontFamily: 'Lato',
                      fontWeight: 400,
                      fontSize: '1.2rem',
                      width: '100%',
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Box>
          </HomeCard>
          <ToastContainer />
        </Box>
      </Box>
    </MainLayout>
  );
};

export default EditPolicy;
