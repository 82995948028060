import { React, useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';

import HomeCard from '../../components/HomeCard';
import {
  Button,
  Dialog,
  Box,
  Typography,
  DialogContent,
  DialogActions,
} from '@mui/material';
import MainLayout from '../../hoc/MainLayout';
import { Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import '../AdminTermsPoliclies/TermsPoliclies.css';
import { collection, getDocs, limit, orderBy, query } from 'firebase/firestore';
import { FirestoreDB } from '../../utils/firebase/firebase';

const AdminTermsPoliclies = () => {
  const [privReadMore, setPrivReadMore] = useState(false);
  const [termsReadMore, setTermsReadMore] = useState(false);
  const [privacyText, setPrivacyText] = useState('');
  const [termsText, setTermsText] = useState('');

  const navigate = useNavigate();

  const handleEdit = (pageName, pageContent) => {
    navigate('/admin/editPolicies', { state: { pageName, pageContent } });
  };

  useEffect(() => {
    const fetchLatestTexts = async () => {
      try {
        const privacyQuery = query(
          collection(FirestoreDB, 'privacy_policies'),
          orderBy('modified_date', 'desc'),
          limit(1)
        );
        const termsQuery = query(
          collection(FirestoreDB, 'terms_conditions'),
          orderBy('modified_date', 'desc'),
          limit(1)
        );

        const privacySnapshot = await getDocs(privacyQuery);
        const termsSnapshot = await getDocs(termsQuery);
        const latestPrivacyDoc = privacySnapshot.docs[0]?.data().policy || '';
        const decodedPrivacyText = atob(latestPrivacyDoc);
        const latestTermsDoc = termsSnapshot.docs[0]?.data().terms || '';
        const decodedTermsText = atob(latestTermsDoc);

        setPrivacyText(decodedPrivacyText);
        setTermsText(decodedTermsText);
      } catch (error) {
        console.error('Error fetching latest data: ', error);
      }
    };

    fetchLatestTexts();
  }, []);

  return (
    <MainLayout>
      <Box className="policiesPageOverviewContainer">
        <Box className="policies-area">
          <HomeCard customClass="policiesPageOVerviewCard">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontFamily: 'Lato',
                  fontWeight: 700,
                  color: '#9291F0',
                }}
                gutterBottom
              >
                Privacy Statement
              </Typography>
              <Button
                onClick={() => handleEdit('Privacy Statement', privacyText)}
              >
                <Edit sx={{ color: 'black' }} />
              </Button>
            </div>
            <div
              className="post__description"
              dangerouslySetInnerHTML={{
                __html:
                  privacyText.length > 1000
                    ? `${privacyText.slice(0, 1000)}...`
                    : privacyText,
              }}
            />

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                size="medium"
                onClick={() => setPrivReadMore(true)}
                sx={{
                  color: '#9291F0',
                  fontFamily: 'Karla',
                  height: '5%',
                  textTransform: 'none',
                  fontSize: '18px',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                Read More
              </Button>
            </Box>
            <Dialog
              open={privReadMore}
              onClose={() => setPrivReadMore(false)}
              maxWidth="lg"
            >
              <DialogContent>
                <div
                  className="post__description"
                  dangerouslySetInnerHTML={{ __html: privacyText }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setPrivReadMore(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </HomeCard>
        </Box>

        <Box className="terms-area">
          <HomeCard customClass="policiesPageOVerviewCard">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontFamily: 'Lato',
                  fontWeight: 700,
                  color: '#9291F0',
                }}
                gutterBottom
              >
                Terms and Conditions
              </Typography>
              <Button
                onClick={() => handleEdit('Terms and Conditions', termsText)}
              >
                <Edit sx={{ color: 'black' }} />
              </Button>
            </div>
            <div
              className="post__description"
              dangerouslySetInnerHTML={{
                __html:
                  termsText.length > 1000
                    ? `${termsText.slice(0, 1000)}...`
                    : termsText,
              }}
            />

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={() => setTermsReadMore(true)}
                sx={{
                  color: '#9291F0',
                  fontFamily: 'Karla',
                  textTransform: 'none',
                  fontSize: '18px',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                Read More
              </Button>
            </Box>
            <Dialog
              open={termsReadMore}
              onClose={() => setTermsReadMore(false)}
              maxWidth="lg"
            >
              <DialogContent>
                <div
                  className="post__description"
                  dangerouslySetInnerHTML={{ __html: termsText }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setTermsReadMore(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </HomeCard>
        </Box>
      </Box>
    </MainLayout>
  );
};

export default AdminTermsPoliclies;
