import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Typography,
} from '@mui/material';
import { ArrowRightAlt } from '@mui/icons-material';
import AuthContext from '../../contexts/AuthContext';
import Progress from '../Progress';

import {
  doc,
  getDocs,
  updateDoc,
  collection,
  orderBy,
  query,
  limit,
} from 'firebase/firestore';
import { FirestoreDB } from '../../utils/firebase/firebase';
import './Header.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const UpdatedAcceptPolicies = () => {
  const [open, setOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [termsData, setTermsData] = useState('');
  const [policyData, setPolicyData] = useState('');

  const { user, updateGlobalUser, typeOfUser } = useContext(AuthContext);

  const fetchLatestTermsAndPolicies = async () => {
    try {
      const termsQuery = query(
        collection(FirestoreDB, 'terms_conditions'),
        orderBy('modified_date', 'desc'),
        limit(1)
      );
      const termsSnapshot = await getDocs(termsQuery);
      const latestTerms = termsSnapshot.docs[0]?.data();

      const privacyQuery = query(
        collection(FirestoreDB, 'privacy_policies'),
        orderBy('modified_date', 'desc'),
        limit(1)
      );
      const privacySnapshot = await getDocs(privacyQuery);
      const latestPrivacy = privacySnapshot.docs[0]?.data();

      // Decode Base64 content if available
      const decodedTerms = latestTerms?.terms
        ? atob(latestTerms.terms)
        : 'No terms and conditions available.';
      const decodedPrivacy = latestPrivacy?.policy
        ? atob(latestPrivacy.policy)
        : 'No privacy policy available.';

      // Update state with decoded values
      setTermsData(decodedTerms);
      setPolicyData(decodedPrivacy);

      return { latestTerms, latestPrivacy };
    } catch (error) {
      console.error('Error fetching terms and privacy policies:', error);
      return { latestTerms: null, latestPrivacy: null };
    }
  };

  const shouldShowDialog = (latestTerms, latestPrivacy) => {
    const termsReviewedDateStr = user?.terms_reviewed_date;
    const policyReviewedDateStr = user?.policy_reviewed_date;

    // Convert the string dates to Date objects
    const termsReviewedDate = termsReviewedDateStr
      ? new Date(
          termsReviewedDateStr.seconds * 1000 ||
            termsReviewedDateStr._seconds * 1000
        )
      : null;
    const policyReviewedDate = policyReviewedDateStr
      ? new Date(
          policyReviewedDateStr.seconds * 1000 ||
            policyReviewedDateStr._seconds * 1000
        )
      : null;

    const latestTermsDate = latestTerms?.modified_date?.toDate();
    const latestPrivacyDate = latestPrivacy?.modified_date?.toDate();

    // Show the dialog if the terms or policies have been modified since the user last reviewed them
    if (!policyReviewedDate || !termsReviewedDate) return true;

    return (
      latestTermsDate?.getTime() > termsReviewedDate?.getTime() ||
      latestPrivacyDate?.getTime() > policyReviewedDate?.getTime()
    );
  };

  const handleComponentMount = async () => {
    const { latestTerms, latestPrivacy } = await fetchLatestTermsAndPolicies();

    if (shouldShowDialog(latestTerms, latestPrivacy)) {
      console.log( 'Dialog should be opened', shouldShowDialog(latestTerms, latestPrivacy)  );
      setOpen(true);
    } else {
      console.log('Dialog should not be opened');
    }
  };

  // Call the handleComponentMount function when the component mounts
  useEffect(() => {
    handleComponentMount();
  }, []);

  const handleClose = () => {
    setOpen(false);
    setCurrentStep(1);
  };

  const nextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const renderTitle = () => {
    switch (currentStep) {
      case 1:
        return 'Mindly Care';
      case 2:
        return 'Mindly Care Inc. Terms of Service';
      case 3:
        return 'Mindly Care Inc. Privacy Policy';
      default:
        return null;
    }
  };

  const renderContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <Typography>
            Our Terms and Policies have been revised. Kindly take a moment to
            review and confirm your acceptance.
          </Typography>
        );
      case 2:
        return (
          <div
            className="post__description"
            dangerouslySetInnerHTML={{ __html: termsData }}
          />
        );
      case 3:
        return (
          <div
            className="post__description"
            dangerouslySetInnerHTML={{ __html: policyData }}
          />
        );
      default:
        return null;
    }
  };

  const renderButton = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="">
            <Button size="small" onClick={nextStep}>
              View Terms <ArrowRightAlt sx={{ ml: 1, fontSize: '30px' }} />
            </Button>
          </div>
        );
      case 2:
        return (
          <Button size="large" onClick={nextStep}>
            Agree Terms & Conditions{' '}
            <ArrowRightAlt sx={{ ml: 1, fontSize: '30px' }} />
          </Button>
        );
      case 3:
        return (
          <Button size="large" onClick={updatedAcceptPoliciesHandler}>
            Agree Privacy & Policy
          </Button>
        );
      default:
        return null;
    }
  };

  const updatedAcceptPoliciesHandlerForPatient = async () => {
    setLoading(true);
    const reviewDate = new Date();
    const userRef = doc(FirestoreDB, 'patientsList', user.uid);
    try {
      // Update Firestore document for the patient
      await updateDoc(userRef, {
        policy_reviewed_date: reviewDate,
        terms_reviewed_date: reviewDate,
      });

      // Update the global user state
      updateGlobalUser((prevUser) => ({
        ...prevUser,
        policy_reviewed_date: reviewDate.toISOString(),
        terms_reviewed_date: reviewDate.toISOString(),
      }));
    } catch (error) {
      console.error('Error updating policies for patient:', error);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  const updatedAcceptPoliciesHandlerForDoctor = async () => {
    setLoading(true);
    const reviewDate = new Date();
    const userRef = doc(FirestoreDB, 'doctorsList', user.uid);
    try {
      // Update Firestore document for the doctor
      await updateDoc(userRef, {
        policy_reviewed_date: reviewDate,
        terms_reviewed_date: reviewDate,
      });

      // Update the global user state
      updateGlobalUser((prevUser) => ({
        ...prevUser,
        policy_reviewed_date: reviewDate.toISOString(),
        terms_reviewed_date: reviewDate.toISOString(),
      }));
    } catch (error) {
      console.error('Error updating policies for doctor:', error);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  const updatedAcceptPoliciesHandler = () => {
    if (typeOfUser === 'DOCTOR') {
      updatedAcceptPoliciesHandlerForDoctor();
    } else if (typeOfUser === 'PATIENT') {
      updatedAcceptPoliciesHandlerForPatient();
    } else {
      console.log('No user found');
    }
  };

  return (
    <div className="UpdatedAcceptPolicies">
      {user && (
        <Box className="flexBox flexCenter" sx={{ gap: 3, mb: 1 }}>
          <Dialog
            className="dialog"
            open={open}
            TransitionComponent={Transition}
            keepMounted
            // onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            {loading && <Progress />}
            <DialogTitle
              sx={{
                fontFamily: 'Lato',
                backgroundColor: 'var(--clr-theme-purple)',
                color: 'white',
              }}
            >
              {renderTitle()}
            </DialogTitle>
            <DialogContent dividers>
              <DialogContentText id="alert-dialog-slide-description">
                {renderContent()}
              </DialogContentText>
            </DialogContent>
            <DialogActions className="renderBtn">
              {renderButton()}
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </div>
  );
};

export default UpdatedAcceptPolicies;
