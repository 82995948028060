import {
    AddAPhoto,
    Email,
    Event,
    Image,
    Person,
    Phone,
  } from '@mui/icons-material';
  import {
    Avatar,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField,
    Tooltip,
    Typography,
    FormControl,
    MenuItem,
    Select,
  } from '@mui/material';
  import { Stack } from '@mui/system';
  import { ref } from 'firebase/storage';
  import { UploadFileToStorage } from '../../utils/firebase/helper';
  import React, { useState, useEffect, useContext } from 'react';
  import { FirebaseStorage } from '../../utils/firebase/firebase';
  import { toast } from 'react-toastify';
  import CustomButton from '../../components/CustomButton';
  import 'react-toastify/dist/ReactToastify.css';
  import {
    updateAdminProfileDetails,
    updatePatientProfileDetails,
  } from '../../api';
  import AuthContext from '../../contexts/AuthContext';
  import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
  import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
  import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
  import { LogAnalyticsEvent } from '../../utils/firebase/firebase';
  import { ANALYTICS_KEYS } from '../../constants/AnalyticKeys';
  
  function AdminEditInformation() {
    const [admin, setAdmin] = useState({});
    const [editOpen, setEditOpen] = useState(false);
    const [profilePicFile, setProfilePicFile] = useState(null);
    const [profilePicURL, setProfilePicUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
  
    const { user, updateGlobalUser } = useContext(AuthContext);
  
    useEffect(() => {
      setAdmin(user);
    }, [user]);
  
    const ChangeHandler = () => {
      setEditOpen(true);
    };
  
    const validateInputs = () => {
      let isValid = true;
  
      if (!admin?.email.trim()) {
        setEmail('Email is required.');
        isValid = false;
      } else if (!/^\S+@\S+\.\S+$/.test(admin.email.trim())) {
        setEmail('Invalid email format.');
        isValid = false;
      } else {
        setEmail('');
      }
      const areaCodeRegex = /^\d{1,3}$/;
      const numberRegex = /^\d{10}$/;
      if (!areaCodeRegex.test(admin?.contactNo?.areaCode)) {
        setContact('Area code max length is 3-digits.');
        isValid = false;
      } else if (!numberRegex.test(admin?.contactNo?.number)) {
        setContact('Phone number must have 10-digits.');
        isValid = false;
      } else if (
        !areaCodeRegex.test(admin?.contactNo?.areaCode) &&
        !numberRegex.test(admin?.contactNo?.number)
      ) {
        setContact('Invalid contact number format.');
        isValid = false;
      } else {
        setContact('');
      }
  
      return isValid;
    };
  
    const handleFileChange = async (e) => {
      const file = e.target.files[0];
      if (file?.size <= 10048576) {
        // Greater than 10MB
        setProfilePicFile(file);
      }
      let url = '';
  
      if (file) {
        let blob = new Blob([file], {
          type: file.type,
        });
        const profileStorageRef = ref(
          FirebaseStorage,
          `profiles/${user?.uid}/${file.name}`
        );
  
        const result = await UploadFileToStorage(profileStorageRef, blob);
  
        if (result.status) {
          url = result.data;
          url && setAdmin({ ...admin, photoURL: url });
          setProfilePicUrl(url);
        }
      }
    };
  
    const showToastMessage = () => {
      toast.success('Profile edited successfully!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    };
    // const patientprofileUpdate = ANALYTICS_KEYS.SETTINGS.PATIENTPROFILEUPDATE;
  
    const UpdateAdminEditChanges = async () => {
      // const patientRef = doc(FirestoreDB, "patientsList", userDetails.uid);
  
      if (!validateInputs()) {
        return;
      }
  
      setIsLoading(true);
  
      const params = {
        email: admin?.email || '',
        fullname: admin?.fullname || '',
        gender: admin?.gender || 0,
        contactNo: admin?.contactNo || { areaCode: '00', number: '000000000' },
        photoURL: admin?.photoURL,
        dob: admin?.dob || 0,
      };
  
      const result = await updateAdminProfileDetails(
        btoa(JSON.stringify(params)),
        user?.uid
      );
      if (result) {
        updateGlobalUser({ ...user, ...admin });
        // LogAnalyticsEvent(patientprofileUpdate, {
        //   userId: user?.uid,
        //   patientName: patient?.fullname,
        //   patientId: user?.uid,
        //   status: 'success',
        // });
      }
      setIsLoading(false);
      setEditOpen(false);
      showToastMessage();
    };
  
    const validateString = (str) => {
      return str?.toString().trim() || '-';
    };
  
    const ClearChanges = () => {
      setAdmin(user);
      setProfilePicFile(null);
      setEditOpen(false);
    };
  
    return (
      <>
        {editOpen && (
          <Box
            sx={{
              paddingLeft: { xs: '10px', sm: '40px', md: '60px' },
              paddingRight: { xs: '10px', sm: '40px', md: '60px' },
              paddingBottom: '25px',
            }}
          >
            <Stack alignItems="center" sx={{ position: 'relative', pb: '25px' }}>
              <Box
                sx={{
                  border: 3,
                  borderColor: 'var(--clr-theme-purple)',
                  borderRadius: 20,
                }}
              >
                <Avatar
                  className="profile-avatar"
                  alt="upload picture"
                  sx={{ width: 150, height: 150 }}
                  src={profilePicURL ? profilePicURL : admin?.photoURL}
                >
                  <Image />
                </Avatar>
              </Box>
  
              <Tooltip title="Max size of 10MB">
                <IconButton
                  className="profile-upload-icon"
                  color="secondary"
                  aria-label="upload picture"
                  component="label"
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={handleFileChange}
                    disabled={isLoading}
                  />
                  <AddAPhoto />
                </IconButton>
              </Tooltip>
              <TextField
                label="Full name"
                value={admin?.fullname}
                onChange={(e) => {
                  setAdmin({
                    ...admin,
                    fullname: e.target.value,
                  });
                }}
                disabled={isLoading}
              />
            </Stack>
            <Grid container spacing={2} sx={{ textTransform: 'capitalize' }}>
              <Grid item xs={12} sm={6} md={6} lg={4} sx={{ flexWrap: 'wrap' }}>
                <Box>
                  <Typography gutterBottom>Gender:</Typography>
                  <FormControl fullWidth>
                    <Select
                      labelId="gender-select-label"
                      id="gender-select"
                      value={admin?.gender || ''}
                      onChange={(e) => {
                        setAdmin({
                          ...admin,
                          gender: e.target.value.trim(),
                        });
                      }}
                    >
                      <MenuItem value="female">Female</MenuItem>
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4} sx={{ flexWrap: 'wrap' }}>
                <Box>
                  <Typography gutterBottom>Contact Number:</Typography>
                  <div style={{ width: '100%' }}>
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        textTransform: 'initial',
                      }}
                    >
                      <TextField
                        fullWidth
                        label="Area code"
                        sx={{ m: 1, width: '30%' }}
                        type={'phone'}
                        value={admin?.contactNo?.areaCode}
                        onChange={(e) => {
                          setAdmin({
                            ...admin,
                            contactNo: {
                              ...admin.contactNo,
                              areaCode: e.target.value.trim(),
                            },
                          });
                        }}
                        disabled={isLoading}
                      />
                      <TextField
                        fullWidth
                        label="Number"
                        type={'phone'}
                        sx={{ m: 1, width: '70%' }}
                        value={admin?.contactNo?.number}
                        onChange={(e) => {
                          setAdmin({
                            ...admin,
                            contactNo: {
                              ...admin.contactNo,
                              number: e.target.value.trim(),
                            },
                          });
                        }}
                        disabled={isLoading}
                      />
                    </div>
                    <Typography
                      className="errorMessage"
                      variant="caption"
                      sx={{
                        paddingLeft: '18px',
                        textTransform: 'none',
                        color: '#d32f2f',
                      }}
                    >
                      {contact}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4} sx={{ flexWrap: 'wrap' }}>
                <Box>
                  <Typography gutterBottom>Date of birth:</Typography>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      label="Date of Birth"
                      value={admin?.dob || null}
                      onChange={(newValue) => {
                        setAdmin({
                          ...admin,
                          dob: newValue._d,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField sx={{ width: '93%' }} {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
        {!editOpen && (
          <Box className="DoctorEdit">
            <Stack alignItems="center" sx={{ position: 'relative' }}>
              <Box
                sx={{
                  textAlign: 'center',
                  border: 3,
                  borderColor: 'var(--clr-theme-purple)',
                  borderRadius: 20,
                }}
              >
                <Avatar
                  alt="Remy Sharp"
                  src={admin?.photoURL}
                  sx={{
                    width: 150,
                    height: 150,
                  }}
                />
              </Box>
              <Typography
                variant="h4"
                sx={{
                  color: 'var(--clr-theme-purple)',
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                }}
              >
                {validateString(admin?.fullname)?.charAt(0).toUpperCase() +
                  validateString(admin?.fullname)?.slice(1).toLowerCase()}
              </Typography>
            </Stack>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} sx={{ flexWrap: 'wrap' }}>
                <Box className="flexBox ">
                  <ListItem>
                    <ListItemIcon>
                      <Email />
                    </ListItemIcon>
                    <ListItemText
                      sx={{ wordBreak: 'break-word' }}
                      primary={validateString(admin?.email)}
                      secondary="Email"
                    />
                  </ListItem>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{ flexWrap: 'wrap' }}>
                <Box className="flexBox ">
                  <ListItem>
                    <ListItemIcon>
                      <Person />
                    </ListItemIcon>
  
                    <ListItemText
                      primary={validateString(admin?.gender)}
                      secondary="Gender"
                    />
                  </ListItem>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{ flexWrap: 'wrap' }}>
                <Box className="flexBox ">
                  <ListItem>
                    <ListItemIcon>
                      <Phone />
                    </ListItemIcon>
                    <ListItemText
                      primary={`+${admin?.contactNo?.areaCode || '00'}-${
                        admin?.contactNo?.number || '00000000'
                      }`}
                      secondary="Contact No."
                    />
                  </ListItem>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{ flexWrap: 'wrap' }}>
                <Box className="flexBox ">
                  <ListItem>
                    <ListItemIcon>
                      <Event />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        admin?.dob &&
                        validateString(
                          new Date(
                            admin?.dob.seconds * 1000 || admin?.dob
                          ).toLocaleDateString()
                        )
                      }
                      secondary="Date of birth"
                    />
                  </ListItem>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{ flexWrap: 'wrap' }}>
                <Box className="flexBox ">
                  <ListItem>
                    <ListItemIcon>
                      <Person />
                    </ListItemIcon>
                    <ListItemText
                      primary={validateString(
                        (() => {
                          const dobInMillis = admin?.user?.dob?.seconds
                            ? admin.user.dob.seconds * 1000
                            : new Date(admin?.dob)?.getTime();
                          const ageInMillis = new Date() - new Date(dobInMillis);
                          const ageInYears =
                            ageInMillis / (1000 * 3600 * 24 * 365);
                          return ageInYears < 1 ? 0 : Math.floor(ageInYears);
                        })()
                      )}
                      secondary="Age"
                    />
                  </ListItem>
                </Box>
              </Grid>
              <Divider />
            </Grid>
          </Box>
        )}
        <Stack flexDirection="row" justifyContent="center" gap={5}>
          <Box sx={{ width: '100%' }}>
            {!editOpen ? (
              <Box className="flexBox flexColumn" gap={1}>
                <CustomButton
                  variant="extended"
                  color="primary"
                  ChangeHandler={ChangeHandler}
                >
                  Edit
                </CustomButton>
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'center',
                  flexWrap: 'wrap',
                  minWidth: '250px',
                  justifyContent: 'center ',
                  gap: 5,
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    width: '20%',
                    minWidth: '250px',
                    fontFamily: 'Lato',
                    textTransform: 'capitalize',
                  }}
                  onClick={ClearChanges}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    backgroundColor: 'var(--clr-theme-purple) !important',
                    width: '20%',
                    minWidth: '250px',
                    fontFamily: 'Lato',
                    textTransform: 'capitalize',
                    '&:disabled': {
                      color: 'var(--clr-theme-white) !important',
                      backgroundColor: 'rgb(211, 211, 211) !important', // You can adjust this styling as needed
                    },
                  }}
                  onClick={UpdateAdminEditChanges}
                  disabled={isLoading}
                >
                  Update
                </Button>
              </Box>
            )}
          </Box>
          {isLoading && (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </Stack>
      </>
    );
  }
  
  export default AdminEditInformation;
  