import React, { useState, useEffect, useContext } from "react";
import { DynamicList } from "./Treatmentm";
import {
  Add,
  AddAlarm,
  Cancel,
  Check,
  Clear,
  Delete,
  Edit,
  InfoOutlined,
  Medication,
  Psychology,
  Save,
} from "@mui/icons-material";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  NativeSelect,
  TextField,
  Button,
  Tooltip,
  styled,
  tooltipClasses,
  Typography,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { FirestoreDB, LogAnalyticsEvent } from "../../utils/firebase/firebase";
import { collection, onSnapshot } from "firebase/firestore";
import { updatePatientTreatmentDetails } from "../../api";
import AuthContext from "../../contexts/AuthContext";
import { ANALYTICS_KEYS } from "../../constants/AnalyticKeys";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontWeight: "bold",
    padding: "10px",
  },
}));

const EditTreatment = ({
  treatment,
  patientID,
  handleCloseDialog,
  getTreatments,
}) => {
  const [editTreatment, setEditTreatment] = useState(treatment || []);
  const { user, typeOfUser } = useContext(AuthContext);
  const [showNewMedForm, setShowNewMedForm] = useState(false);
  const [showNewTherapyForm, setShowNewTherapyForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleNewMedFormToggle = () => {
    setShowNewMedForm((prev) => !prev);
  };

  const handleNewTherapyFormToggle = () => {
    setShowNewTherapyForm((prev) => !prev);
  };

  const handleMedFormClose = () => {
    setShowNewMedForm(false);
  };

  const handleTherapyFormClose = () => {
    setShowNewTherapyForm(false);
  };

  const handleNewMedSubmit = (newMedData) => {
    console.log("New Medication Data:", newMedData);
    const updatedMeds = [...editTreatment.meds];
    updatedMeds.push(newMedData);
    setEditTreatment({
      ...editTreatment,
      meds: updatedMeds,
    });
  };

  const handleNewTherapySubmit = (newTherapyData) => {
    console.log("New Medication Data:", newTherapyData);
    const updatedTherapiess = [...editTreatment.therapies];
    updatedTherapiess.push(newTherapyData);
    setEditTreatment({
      ...editTreatment,
      therapies: updatedTherapiess,
    });
  };

  const updateTreatmentInfoToDB = async () => {
    setIsLoading(true);
    try {
      const editedTreatment = {
        meds: btoa(JSON.stringify(editTreatment?.meds || [])),
        therapies: btoa(JSON.stringify(editTreatment.therapies || [])),
        treatment_name: btoa(editTreatment.treatment_name || ""),
        userName: btoa(user.fullname || ""),
        userType: typeOfUser,
        isDoctorApproved: typeOfUser === "DOCTOR" ? true : false,
      };
      // console.log(editedTreatment, "editedTreatment");
      const response = await updatePatientTreatmentDetails(
        user?.uid, //doctorid
        patientID,
        editTreatment?.id,
        editedTreatment
      );
      if (response) {
        await getTreatments();
        handleCloseDialog();
        LogAnalyticsEvent(ANALYTICS_KEYS.PATIENTSLIST.UPDATETREATMENT, {
          eventName: "Update Treatment Changes",
          doctorId: user?.uid || "unknown_doctor",
          patientID: patientID || "unknown_patient",
          treatmentID: editTreatment?.id || "unknown_treatment",
          isDoctorApproved: typeOfUser === "DOCTOR",
          numberOfMeds: editTreatment?.meds?.length || 0,
          numberOfTherapies: editTreatment?.therapies?.length || 0,
          message: "Update Treatment Sucessfully",
        });
      }
      console.log(response, "response");
      setIsLoading(false);
    } catch (e) {
      console.log(e, "error while updating patient treatment info");
      handleCloseDialog();
      setIsLoading(false);
    }
  };

  return (
    <div>
      <TextField
        label={"Diagnosis"}
        value={editTreatment?.treatment_name}
        onChange={(e) => {
          setEditTreatment({
            ...editTreatment,
            treatment_name: e.target.value,
          });
        }}
        fullWidth
      />
      <DynamicEditList
        label={"Meds"}
        list={[...editTreatment?.meds]}
        treatmentID={treatment?.id}
        icon={<Medication />}
        editTreatment={editTreatment}
        setEditTreatment={setEditTreatment}
      />
      <DynamicEditList
        label={"Therapy"}
        list={[...editTreatment?.therapies]}
        treatmentID={treatment?.id}
        icon={<Psychology />}
        editTreatment={editTreatment}
        setEditTreatment={setEditTreatment}
      />
      {showNewMedForm && (
        <AddNewMedForm
          onSubmit={handleNewMedSubmit}
          setIsLoading={setIsLoading}
          onClose={handleMedFormClose}
        />
      )}
      {showNewTherapyForm && (
        <AddNewTherapyForm
          onSubmit={handleNewTherapySubmit}
          onClose={handleTherapyFormClose}
        />
      )}
      <Box
        className="fullWidth flexBox flexColumn"
        style={{
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: "1em",
        }}
      >
        {/* <Button
          sx={{
            // backgroundColor: "var(--clr-theme-purple)!important",
            textTransform: "capitalize",
            fontSize: "18px",
            color: "var(--clr-theme-purple)!important",
            border: "1px solid var(--clr-theme-purple)!important",
            fontFamily: "Lato",
            ":hover": {
              background:
                "linear-gradient(270deg,var(--ds-background-accent-purple-bolder, #403294) 0%,var(--ds-background-neutral-bold-pressed, #172b4d) 100%)",
              color: "var(--clr-theme-white)!important",
              border: "none",
            },
          }}
          variant="outlined"
          onClick={handleCloseDialog}
        >
          Cancel 
        </Button> */}
        <Box className="fullWidth flexBox" gap={1}>
          <Button
            onClick={handleNewMedFormToggle}
            className="customButton"
            fullWidth
            sx={{
              backgroundColor: "var(--clr-theme-purple)",
              textTransform: "capitalize",
              fontSize: "18px",
              fontFamily: "Lato",
            }}
            variant="contained"
          >
            <Add /> Add New Medication
          </Button>

          <Button
            onClick={handleNewTherapyFormToggle}
            className="customButton"
            fullWidth
            sx={{
              backgroundColor: "var(--clr-theme-purple)",
              textTransform: "capitalize",
              fontSize: "18px",
              fontFamily: "Lato",
            }}
            variant="contained"
          >
            <Add /> Add New Therapy
          </Button>
        </Box>

        <Button
          className="customButton"
          fullWidth
          sx={{
            backgroundColor: "var(--clr-theme-purple)",
            textTransform: "capitalize",
            fontSize: "18px",
            fontFamily: "Lato",
            mt: 1,
          }}
          variant="contained"
          onClick={updateTreatmentInfoToDB}
          autoFocus
        >
          Update All Changes{" "}
          {isLoading ? (
            <CircularProgress color="inherit" sx={{ ml: 2 }} />
          ) : (
            <Save />
          )}
        </Button>
      </Box>
    </div>
  );
};

const DynamicEditList = ({
  label,
  list,
  icon,
  treatmentID,
  treatment,
  editTreatment,
  setEditTreatment,
}) => {
  const [editOpen, setEditOpen] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [symptoms, setSymptoms] = useState([]);
  const [isCustomSymptom, setIsCustomSymptom] = useState(false);
  const initialSymptom = (treatment && treatment.symptom) || "";
  const [selectedSymptom, setSelectedSymptom] = useState(initialSymptom);
  const [editType, setEditType] = useState(null);

  const getSymptoms = async () => {
    const symptomsRef = collection(FirestoreDB, "symptoms");
    return onSnapshot(
      symptomsRef,
      (querySnapshot) => {
        const symptomIds = querySnapshot.docs.map((doc) => doc.id);
        setSymptoms(symptomIds);
      },
      (error) => {
        console.log("Error listening to symptoms:", error);
      }
    );
  };

  const symptomHandler = (e) => {
    const selectedValue = e.target.value;
    setSelectedSymptom(selectedValue);

    if (selectedValue !== "other") {
      changeHandler(e, "symptom");
      setIsCustomSymptom(false);
    } else {
      setIsCustomSymptom(true);
    }
  };

  useEffect(() => {
    getSymptoms();
  }, []);

  useEffect(() => {
    if (!editTreatment[label.toLowerCase()]) {
      setEditTreatment({ ...editTreatment, [label.toLowerCase()]: [] });
    }
  }, [editTreatment, label]);

  const handleEditOpen = (item, type) => {
    setEditItem(item);
    setEditType(type);
    setEditOpen(true);
  };

  const handleClose = () => {
    setEditItem(null);
    setEditType(null);
    setEditOpen(false);
  };

  const deleteItem = (item, label) => {
    console.log("delete", item, label);
    if (label === "meds") {
      const updatedMeds = [...editTreatment.meds];
      setEditTreatment({
        ...editTreatment,
        meds: [...updatedMeds.filter((x) => x !== item)],
      });
    } else if (label === "therapy") {
      const updatedTherapiess = [...editTreatment.therapies];
      setEditTreatment({
        ...editTreatment,
        therapies: [...updatedTherapiess.filter((x) => x !== item)],
      });
    }
  };

  const saveEdit = () => {
    const tempItems =
      editTreatment[editType === "therapy" ? "therapies" : "meds"];
    if (tempItems && tempItems.length > 0 && editItem) {
      const index = tempItems.findIndex((item) => item.id === editItem.id);

      if (index !== -1) {
        tempItems[index] = { ...editItem };
        setEditTreatment({
          ...editTreatment,
          [label.toLowerCase()]: tempItems,
        });
      }
    }

    handleClose();
  };

  const changeHandler = (e, field) => {
    setEditItem({ ...editItem, [field]: e.target.value });
  };

  return (
    <Box className="Treatment">
      <List
        subheader={label}
        sx={{ display: "flex", flexDirection: "column", gap: 1 }}
      >
        {list.map((item, idx) => (
          <ListItem
            sx={{
              color: "#454545",
              background: "var(--clr-theme-purple-20)",
              borderRadius: "5px",
              border: "1px solid lightgray",
            }}
            key={idx}
            secondaryAction={
              <>
                <IconButton
                  edge="end"
                  aria-label="edit"
                  onClick={() => handleEditOpen(item, label.toLowerCase())}
                  color="inherit"
                >
                  <Edit color="action" />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="edit"
                  onClick={() => deleteItem(item, label.toLowerCase())}
                  color="inherit"
                >
                  <Delete color="error" />
                </IconButton>
              </>
            }
          >
            <ListItemIcon sx={{ color: "var(--clr-success)" }}>
              {icon}
            </ListItemIcon>
            <ListItemText>
              {label === "Therapy" ? (
                <div style={{ fontFamily: "Lato" }}>
                  <strong>Therapy name:</strong> {item?.therapy_name}
                </div>
              ) : (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {item?.drug_name && (
                    <div style={{ marginRight: "20px", fontFamily: "Lato" }}>
                      <strong>Drug name:</strong> {item.drug_name}
                    </div>
                  )}
                  {item?.dosage && (
                    <div style={{ marginRight: "20px", fontFamily: "Lato" }}>
                      <strong>Dosage:</strong> {item.dosage} mg
                    </div>
                  )}
                  {item?.usage && (
                    <div style={{ marginRight: "20px", fontFamily: "Lato" }}>
                      <strong>Usage:</strong> {item.usage} times
                    </div>
                  )}
                  {item?.range && (
                    <div style={{ marginRight: "20px", fontFamily: "Lato" }}>
                      <strong>Range:</strong> {item.range}
                    </div>
                  )}
                  {item?.tradeName && (
                    <div style={{ marginRight: "20px", fontFamily: "Lato" }}>
                      <strong>Trade name:</strong> {item.tradeName}
                    </div>
                  )}
                  {item?.symptom && (
                    <div style={{ marginRight: "20px", fontFamily: "Lato" }}>
                      <strong>Symptom:</strong> {item.symptom}
                    </div>
                  )}
                </div>
              )}
            </ListItemText>
          </ListItem>
        ))}
      </List>
      {editOpen && (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            alignItems: "center",
            mt: 1,
          }}
        >
          {editType === "meds" && (
            <>
              <TextField
                sx={{ width: "40%" }}
                label="Drug name"
                value={editItem?.drug_name}
                onChange={(e) => changeHandler(e, "drug_name")}
              />
              <TextField
                sx={{ width: "40%" }}
                label="Trade name"
                value={editItem?.tradeName}
                onChange={(e) => changeHandler(e, "tradeName")}
              />
              <TextField
                sx={{ width: "40%" }}
                type="number"
                label="Dosage"
                value={editItem?.dosage}
                onChange={(e) => changeHandler(e, "dosage")}
                InputProps={{
                  inputProps: {
                    max: 100,
                    min: 0,
                    step: 0.1,
                  },
                  endAdornment: (
                    <InputAdornment position="end">mg</InputAdornment>
                  ),
                }}
              />
              <TextField
                sx={{ width: "40%" }}
                label="Usage"
                type="number"
                value={editItem?.usage}
                onChange={(e) => changeHandler(e, "usage")}
                InputProps={{
                  inputProps: {
                    max: 100,
                    min: 0,
                    step: 1,
                  },
                  endAdornment: (
                    <InputAdornment position="end">times</InputAdornment>
                  ),
                }}
              />
              {/* <TextField
                sx={{ width: "40%" }}
                label="Range"
                value={editItem?.range}
                onChange={(e) => changeHandler(e, "range")}
              /> */}
              <FormControl>
                <NativeSelect
                  // defaultValue={"Per Day"}
                  sx={{
                    p: 1,
                    borderColor: "transparent",
                    borderRadius: 2,
                    backgroundColor: "var(--clr-theme-purple-50)",
                  }}
                  autoFocus={false}
                  name="range"
                  value={editItem?.range}
                  onChange={(e) => changeHandler(e, "range")}
                  // disabled={med?.drug_name.length === 0}
                >
                  <option value="Per Day">per day</option>
                  <option value="Per Week">per week</option>
                  <option value="Per Month">per month</option>
                </NativeSelect>
              </FormControl>
              <TextField
                sx={{ width: "40%" }}
                label="Side effects"
                value={editItem?.sideEffects}
                onChange={(e) => changeHandler(e, "sideEffects")}
              />
              {/* <TextField
                sx={{ width: "40%" }}
                label="symptom"
                value={editItem?.symptom}
                onChange={(e) => changeHandler(e, "symptom")}
              /> */}
              <FormControl sx={{ width: "40%" }}>
                <NativeSelect
                  fullWidth
                  sx={{
                    p: 1,
                    border: "1px solid #ccc",
                    borderRadius: "15px",
                    backgroundColor: "var(--clr-theme-white)",
                    display: "flex",
                  }}
                  value={isCustomSymptom ? "other" : selectedSymptom}
                  onChange={symptomHandler}
                  inputProps={{
                    name: "symptom",
                    id: "symptom-native-select",
                  }}
                >
                  <option value="" disabled>
                    Select symptom
                  </option>
                  {symptoms?.length > 0 &&
                    symptoms?.map((item, i) => (
                      <option key={i} value={item}>
                        {item}
                      </option>
                    ))}
                  <option value="other">Other</option>
                </NativeSelect>
              </FormControl>
              {isCustomSymptom ? (
                <TextField
                  sx={{ width: "40%" }}
                  label="Add symptom"
                  onChange={(e) => {
                    changeHandler(e, "symptom");
                  }}
                />
              ) : null}
            </>
          )}
          {editType === "therapy" && (
            <>
              <TextField
                sx={{ width: "40%" }}
                label="Therapy name"
                value={editItem?.therapy_name}
                onChange={(e) => changeHandler(e, "therapy_name")}
              />
              <TextField
                sx={{ width: "40%" }}
                label="Cost per hour"
                value={editItem?.costPerHours}
                onChange={(e) => changeHandler(e, "costPerHours")}
              />
              <TextField
                sx={{ width: "40%" }}
                label="Hours per session"
                value={editItem?.hoursPerSession}
                onChange={(e) => changeHandler(e, "hoursPerSession")}
              />
              <TextField
                sx={{ width: "40%" }}
                label="Sessions per week"
                value={editItem?.sessionsPerWeek}
                onChange={(e) => changeHandler(e, "sessionsPerWeek")}
              />
            </>
          )}
          <Button
          className="customButton"
          sx={{
            backgroundColor: "var(--clr-theme-purple)",
            textTransform: "capitalize",
            fontSize: "16px",
            fontFamily: "Lato",
            mt: 1,
          }}
          variant="contained"
          onClick={saveEdit}
          autoFocus
        >
          Save Changes
        </Button>
          {/* <IconButton onClick={saveEdit}>
            <Check color="success" sx={{ fontSize: "1.5em" }} />
          </IconButton> */}

          <IconButton onClick={handleClose}>
            <Clear color="error" sx={{ fontSize: "1.5em" }} />
          </IconButton>
          <Box
            className="flexBox flexCenter"
            sx={{
              gap: 0.5,
              mb: 1,
            }}
          >
            <InfoOutlined sx={{ color: "green", mt: 0.7 }} />
            <Typography
              variant="subtitle1"
              sx={{ color: "gray", fontFamily: "Lato" }}
            >
              Please submit your updated treatment information by clicking on save changes
              {/* <span
                style={{
                  fontSize: "1.5em",
                  fontWeight: "bold",
                  color: "green",
                }}
              >
                &#10003;
              </span> */}
              .
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export const AddNewMedForm = ({
  onSubmit,
  setIsLoading,
  treatment,
  onClose,
}) => {
  const [newMed, setNewMed] = useState({
    drug_name: "",
    dosage: "",
    tradeName: "",
    usage: "",
    range: "Per Day",
    sideEffects: "",
    treats: "",
  });
  const [symptoms, setSymptoms] = useState([]);
  const [isCustomSymptom, setIsCustomSymptom] = useState(false);
  const initialSymptom = (treatment && treatment.symptom) || "";
  const [selectedSymptom, setSelectedSymptom] = useState(initialSymptom);

  const getSymptoms = async () => {
    const symptomsRef = collection(FirestoreDB, "symptoms");
    return onSnapshot(
      symptomsRef,
      (querySnapshot) => {
        const symptomIds = querySnapshot.docs.map((doc) => doc.id);
        setSymptoms(symptomIds);
      },
      (error) => {
        console.log("Error listening to symptoms:", error);
      }
    );
  };

  const symptomHandler = (e) => {
    const selectedValue = e.target.value;
    setSelectedSymptom(selectedValue);

    if (selectedValue !== "other") {
      handleChange(e);
      setIsCustomSymptom(false);
    } else {
      setIsCustomSymptom(true);
    }
  };

  useEffect(() => {
    getSymptoms();
  }, []);

  useEffect(() => {
    setNewMed({
      drug_name: "",
      dosage: "",
      tradeName: "",
      usage: "",
      range: "Per Day",
      sideEffects: "",
      treats: "",
      symptom: "",
    });
  }, [onClose]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewMed((prevMed) => ({
      ...prevMed,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    setIsLoading(true);
    var uniqMedId = "Med" + new Date()?.getTime();
    e.preventDefault();
    onSubmit({ ...newMed, id: uniqMedId });
    setNewMed({
      drug_name: "",
      dosage: "",
      tradeName: "",
      usage: "",
      range: "Per Day",
      sideEffects: "",
      treats: "",
    });
    setIsLoading(false);
  };

  return (
    <Box>
      <Typography
        gutterBottom
        sx={{ fontFamily: "lato", color: "var(--clr-theme-purple)" }}
      >
        Meds
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box className="fullWidth flexBox flexWrap" sx={{ gap: 1 }}>
          <TextField
            sx={{ width: "40%" }}
            label="Drug name"
            name="drug_name"
            value={newMed.drug_name}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            sx={{ width: "40%" }}
            label="Trade name"
            name="tradeName"
            value={newMed.tradeName}
            onChange={handleChange}
            disabled={newMed?.drug_name.length === 0}
            fullWidth
          />
          <TextField
            sx={{ width: "40%" }}
            type="number"
            name="dosage"
            label="Dosage"
            value={newMed.dosage}
            onChange={handleChange}
            disabled={newMed?.drug_name.length === 0}
            fullWidth
            required
            InputProps={{
              inputProps: {
                max: 100,
                min: 0,
                step: 0.1,
              },
              endAdornment: <InputAdornment position="end">mg</InputAdornment>,
            }}
          />
          <TextField
            name="usage"
            sx={{ width: "40%" }}
            type="number"
            label="Usage"
            value={newMed.usage}
            onChange={handleChange}
            disabled={newMed?.drug_name.length === 0}
            fullWidth
            required
            InputProps={{
              inputProps: {
                max: 100,
                min: 0,
                step: 1,
              },
              endAdornment: (
                <InputAdornment position="end">times</InputAdornment>
              ),
            }}
          />
          <FormControl sx={{ width: "40%" }}>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Select range
            </InputLabel>
            <NativeSelect
              sx={{
                p: 1,
                borderColor: "transparent",
                borderRadius: 2,
                // backgroundColor: "var(--clr-theme-purple-50)",
              }}
              name="range"
              value={newMed.range}
              onChange={handleChange}
              disabled={newMed?.drug_name.length === 0}
            >
              <option value="Per Day">Per day</option>
              <option value="Per Week">Per week</option>
              <option value="Per Month">Per month</option>
            </NativeSelect>
          </FormControl>
          <TextField
            sx={{ width: "40%" }}
            label="Side effects"
            name="sideEffects"
            value={newMed.sideEffects}
            onChange={handleChange}
            disabled={newMed?.drug_name.length === 0}
            fullWidth
          />
          <FormControl sx={{ width: "40%" }}>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Symptom
            </InputLabel>
            <NativeSelect
              fullWidth
              sx={{
                p: 1.8,
                // border: "1px solid #ccc",
                // borderRadius: "15px",
                // backgroundColor: "var(--clr-theme-white)",
                // display: "flex",
              }}
              value={isCustomSymptom ? "other" : selectedSymptom}
              onChange={symptomHandler}
              name="symptom"
              inputProps={{
                name: "symptom",
                id: "symptom-native-select",
              }}
            >
              <option value="" disabled>
                Select symptom
              </option>
              {symptoms?.length > 0 &&
                symptoms?.map((item, i) => (
                  <option key={i} value={item}>
                    {item}
                  </option>
                ))}
              <option value="other">Other</option>
            </NativeSelect>
          </FormControl>
          {isCustomSymptom ? (
            <TextField
              sx={{ width: "40%" }}
              label="Add symptom"
              name="symptom"
              onChange={handleChange}
            />
          ) : null}
          {/* <IconButton type="submit">
            <Check color="success" sx={{ fontSize: "1.5em" }} />
          </IconButton> */}
          <Button
          className="customButton"
          sx={{
            backgroundColor: "var(--clr-theme-purple)",
            textTransform: "capitalize",
            fontSize: "16px",
            fontFamily: "Lato",
            mt: 1,
          }}
          variant="contained"
          autoFocus
          type="submit"
        >
          Save Changes
        </Button>
          <IconButton onClick={onClose}>
            <Clear color="error" sx={{ fontSize: "1.5em" }} />
          </IconButton>
        </Box>
        <Box
          className="flexBox "
          sx={{
            gap: 0.5,
            mb: 1,
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <InfoOutlined sx={{ color: "green", mt: 0.7 }} />
          <Typography
            variant="subtitle1"
            sx={{ color: "gray", fontFamily: "Lato" }}
          >
            Please submit your new meds information by clicking on save changes
            {/* <span
              style={{
                fontSize: "1.5em",
                fontWeight: "bold",
                color: "green",
              }}
            >
              &#10003;
            </span> */}
            .
          </Typography>
        </Box>
      </form>
    </Box>
  );
};

export const AddNewTherapyForm = ({ onSubmit, onClose }) => {
  const [newTherapy, setNewTherapy] = useState({
    costPerHours: "",
    hoursPerSession: "",
    sessionsPerWeek: "",
    therapy_name: "",
  });

  useEffect(() => {
    setNewTherapy({
      costPerHours: "",
      hoursPerSession: "",
      sessionsPerWeek: "",
      therapy_name: "",
    });
  }, [onClose]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewTherapy((prevTherapy) => ({
      ...prevTherapy,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    var uniqTherapyId = "Therapy" + new Date()?.getTime();
    e.preventDefault();
    onSubmit({ ...newTherapy, id: uniqTherapyId });
    setNewTherapy({
      costPerHours: "",
      hoursPerSession: "",
      sessionsPerWeek: "",
      therapy_name: "",
    });
  };

  return (
    <Box>
      <Typography
        gutterBottom
        sx={{ fontFamily: "lato", color: "var(--clr-theme-purple)" }}
      >
        Therapy
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box className="fullWidth flexBox flexWrap" sx={{ gap: 1 }}>
          <TextField
            sx={{ width: "40%" }}
            label="Therapy name"
            name="therapy_name"
            value={newTherapy?.therapy_name}
            inputProps={{ style: { color: "black" } }}
            onChange={handleChange}
          />
          <TextField
            sx={{ width: "40%" }}
            label="Cost per hours"
            name="costPerHours"
            value={newTherapy?.costPerHours}
            disabled={newTherapy?.therapy_name.length === 0}
            onChange={handleChange}
          />
          <TextField
            sx={{ width: "40%" }}
            label="Hours per session"
            name="hoursPerSession"
            value={newTherapy?.hoursPerSession}
            disabled={newTherapy?.therapy_name.length === 0}
            onChange={handleChange}
          />
          <TextField
            sx={{ width: "40%" }}
            label="Sessions per week"
            name="sessionsPerWeek"
            value={newTherapy?.sessionsPerWeek}
            disabled={newTherapy?.therapy_name.length === 0}
            onChange={handleChange}
          />
          {/* <IconButton type="submit">
            <Check color="success" sx={{ fontSize: "1.5em" }} />
          </IconButton> */}
          <Button
          className="customButton"
          sx={{
            backgroundColor: "var(--clr-theme-purple)",
            textTransform: "capitalize",
            fontSize: "16px",
            fontFamily: "Lato",
            mt: 1,
          }}
          variant="contained"
          autoFocus
          type="submit"
        >
          Save Changes
        </Button>
          <IconButton onClick={onClose}>
            <Clear color="error" sx={{ fontSize: "1.5em" }} />
          </IconButton>
        </Box>
        <Box
          className="flexBox "
          sx={{
            gap: 0.5,
            mb: 1,
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <InfoOutlined sx={{ color: "green", mt: 0.7 }} />
          <Typography
            variant="subtitle1"
            sx={{ color: "gray", fontFamily: "Lato" }}
          >
            Please submit your new therapy information by clicking on save changes
            {/* <span
              style={{
                fontSize: "1.5em",
                fontWeight: "bold",
                color: "green",
              }}
            >
              &#10003;
            </span> */}
            .
          </Typography>
        </Box>
      </form>
    </Box>
  );
};

export default EditTreatment;