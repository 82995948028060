import React, { useContext, useState } from 'react';
import {
  Box,
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Fab,
  Dialog,
  DialogContent,
  Button,
  Tooltip,
  tooltipClasses,
} from '@mui/material';

import Header from '../../components/Header';
import LeftPanel from '../../components/LeftPanel';
import AuthContext from '../../contexts/AuthContext';
import { UIContext } from '../../contexts/UIProvider';
import { styled } from '@mui/material/styles';
import './MainLayout.css';
import VideoUpload from '../../components/VideoUpload';
import { Close, CloseRounded, Event } from '@mui/icons-material';
import QuickNotesModel from '../../components/QuickNotesModel';
import Reminders from '../../components/Reminders';
import EmailVerificationNote from '../../pages/EmailVerificationNote';
import FallbackScreen from '../../components/FallbackScreen';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontWeight: 'bold',
    padding: '10px',
  },
}));

const MainLayout = ({ children, onclick }) => {
  const {
    user,
    setUser,
    typeOfUser,
    openRightPanel,
    setOpenRightPanel,
    panelContent,
    isNotificationPanel,
  } = useContext(AuthContext);
  const {
    bottomDrawer,
    setBottomDrawer,
    isDrawerOpen,
    setIsDrawerOpen,
    drawerVariant,
  } = useContext(UIContext);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!user) {
    return <FallbackScreen />;
  }
  const specificDate = new Date(Date.UTC(2024, 9, 20, 5, 34, 12));
  const formattedDate = specificDate.toUTCString();

  return (
    <div className="MainLayout">
      {Boolean(user?.emailVerified || user?.metadata?.creationTime < formattedDate )? (
        <>
          <LeftPanel
            userType={typeOfUser}
            drawerVariant={drawerVariant}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
          />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 1,
              display: 'flex',
              flexDirection: 'column',
              overflowX: 'hidden',
              overflowY: 'hidden',
            }}
          >
            <Header />
            <Box sx={{ pt: 2, flex: 1, overflowY: 'auto' }}>{children}</Box>
          </Box>
          {typeOfUser !== 'ADMIN' && (
            <VideoUpload user={user} typeOfUser={typeOfUser} />
          )}
          <QuickNotesModel user={user} typeOfUser={typeOfUser} />
          {typeOfUser === 'DOCTOR' && (
            <BootstrapTooltip title="Set Reminders" placement="left">
              <Fab
                size="large"
                sx={{
                  position: 'absolute',
                  bottom: '15%',
                  right: '2%',
                  backgroundColor: 'var(--clr-theme-purple)!important',
                }}
                color="secondary"
                aria-label="edit"
                onClick={handleClickOpen}
              >
                <Event />
              </Fab>
            </BootstrapTooltip>
          )}
          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth="lg"
          >
            <AppBar
              sx={{
                position: 'relative',
                backgroundColor: 'transparent',
                boxShadow: 'none',
              }}
            >
              <Toolbar>
                <Typography
                  sx={{
                    flex: 1,
                    color: 'black',
                    fontSize: '1.8rem',
                    fontFamily: 'Lato',
                  }}
                  variant="h6"
                  component="div"
                >
                  Reminders
                </Typography>
                <Button autoFocus color="inherit" onClick={handleClose}>
                  <Close sx={{ color: 'black' }} />
                </Button>
              </Toolbar>
            </AppBar>
            <DialogContent sx={{ width: '42vw' }}>
              <Reminders />
            </DialogContent>
          </Dialog>
          <Drawer
            sx={{ zIndex: 1300 }}
            anchor="bottom"
            open={bottomDrawer.show}
            transitionDuration={250}
            PaperProps={{
              sx: {
                width: '100%',
                height: '100%',
                alignItems: 'center',
              },
            }}
          >
            <AppBar sx={{ position: 'sticky' }}>
              <Toolbar
                sx={{
                  color: 'white',
                  backgroundColor: 'var(--clr-theme-purple)',
                }}
              >
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    setBottomDrawer({
                      ...bottomDrawer,
                      patientName: '',
                      title: '',
                      show: false,
                      content: null,
                      patientPhoto: null,
                    });
                  }}
                  aria-label="close"
                >
                  <Close />
                </IconButton>
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                >
                  {bottomDrawer?.title || 'No Title'}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Avatar
                    src={bottomDrawer?.patientPhoto || null}
                    variant="square"
                  />
                  <Typography>
                    {bottomDrawer?.patientName
                      ? `${bottomDrawer?.patientName
                          .split(/\s+/)
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() +
                              word.slice(1).toLowerCase()
                          )
                          .join(' ')}`
                      : '' || '-'}
                  </Typography>
                </Box>
              </Toolbar>
            </AppBar>
            <div className="fullHeight fullWidth" style={{ padding: '10px' }}>
              {bottomDrawer?.content}
            </div>
          </Drawer>
          <Drawer
            sx={{ zIndex: 1350 }}
            anchor="right"
            open={openRightPanel}
            transitionDuration={250}
            PaperProps={{
              sx: {
                width: `${isNotificationPanel ? '30%' : '60%'}`,
                display: 'flex',
                padding: 1,
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
              },
            }}
          >
            <Box sx={{ alignItems: 'end', textAlign: 'end' }}>
              <IconButton
                size="large"
                color="info"
                onClick={() => {
                  setOpenRightPanel(false);
                }}
              >
                <CloseRounded />
              </IconButton>
            </Box>
            <Box className="fullWidth fullHeight" sx={{ overflowY: 'auto' }}>
              {panelContent}
            </Box>
          </Drawer>
        </>
      ) : (
        <Box>
          <EmailVerificationNote setUser={setUser} />
        </Box>
      )}
    </div>
  );
};

export default MainLayout;