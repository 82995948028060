import {
  CalendarMonthOutlined,
  HomeOutlined,
  PeopleOutlined,
  SettingsOutlined,
  QueryStatsOutlined,
  MenuBookOutlined,
  Message,
} from '@mui/icons-material';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import { MdFiberNew } from 'react-icons/md';

export const LeftMenu = [
  { key: 'Home', name: 'My Dashboard', icon: <HomeOutlined />, path: '/' },
  {
    key: 'Patient List',
    name: 'Patient List',
    icon: <PeopleOutlined sx={{ color: 'var(--clr-theme-white)' }} />,
    collapsible: true,
  },
  {
    key: 'Appointments',
    name: 'Appointments',
    icon: <CalendarMonthOutlined />,
    path: '/appointments',
  },
  {
    key: 'Statistics',
    name: 'Statistics',
    icon: <QueryStatsOutlined />,
    path: '/statistics',
  },
  {
    key: 'HistoricalData',
    name: 'Historical Data',
    icon: <MenuBookOutlined />,
    path: '/historical',
  },
  {
    key: 'Messaging',
    name: 'Messaging',
    icon: <Message />,
    additionalIcon: <MdFiberNew />,
    path: '/message',
  },
  {
    key: 'Settings',
    name: 'Settings',
    icon: <SettingsOutlined />,
    path: '/settings',
  },
];

export const AdminLeftMenu = [
  // { key: "Home", name: "Admin Dashboard", icon: <HomeOutlined />, path: "/admin" },
  // {
  //   key: "Reports and Analytics",
  //   name: "Reports and Analytics",
  //   icon: <PeopleOutlined sx={{ color: "var(--clr-theme-white)" }} />,
  //   path: "/admin/reports",
  // },
  // {
  //   key: "Blogs",
  //   name: "Blogs",
  //   icon: <PeopleOutlined sx={{ color: "var(--clr-theme-white)" }} />,
  //   path: "/admin/blogs",
  // },
  // {
  //   key: "Feedback Responses",
  //   name: "Feedback Responses",
  //   icon: <PeopleOutlined sx={{ color: "var(--clr-theme-white)" }} />,
  //   path: "/admin/feedbackresponse",
  // },
  {
    key: 'Home',
    name: 'Terms and Policies',
    icon: <PeopleOutlined sx={{ color: 'var(--clr-theme-white)' }} />,
    path: '/admin/terms',
  },
];

export const LeftMenu_BottomSection = [
  {
    key: 'Privacy',
    name: 'Privacy Statement',
    icon: <PrivacyTipIcon fontSize="small" />,
    path: '/Privacy',
  },
  {
    key: 'Termcondition',
    name: 'Terms and Conditions',
    icon: <BrandingWatermarkIcon fontSize="small" />,
    path: '/Termcondition',
  },
  {
    key: 'Contact',
    name: 'Contact Information',
    icon: <ContactPhoneIcon fontSize="small" />,
    path: '/Contact',
  },
  // {
  //   key: "Userguide",
  //   name: "User guide",
  //   icon: <MenuBookIcon fontSize="small" />,
  //   path: "/Userguide",
  // },
];

export const DRAWER_WIDTH = 297;
