import React, { useEffect, useState } from 'react';
import MainLayout from '../../hoc/MainLayout';
import { Card, CardContent } from '@mui/material';
import { collection, getDocs, limit, orderBy, query } from 'firebase/firestore';
import { FirestoreDB } from '../../utils/firebase/firebase';

const Settings = () => {
  const [privacyText, setPrivacyText] = useState('');

  useEffect(() => {
    const fetchLatestTexts = async () => {
      try {
        const privacyQuery = query(
          collection(FirestoreDB, 'privacy_policies'),
          orderBy('modified_date', 'desc'),
          limit(1)
        );

        const privacySnapshot = await getDocs(privacyQuery);

        const latestPrivacyDoc = privacySnapshot.docs[0]?.data().policy || '';
        const decodedPrivacyText = atob(latestPrivacyDoc);

        setPrivacyText(decodedPrivacyText);
      } catch (error) {
        console.error('Error fetching latest data: ', error);
      }
    };

    fetchLatestTexts();
  }, []);

  return (
    <MainLayout>
      <Card sx={{ maxWidth: '90%', margin: '20px auto', padding: 2 }}>
        <CardContent>
          <div
            className="post__description"
            dangerouslySetInnerHTML={{ __html: privacyText }}
          />
        </CardContent>
      </Card>
    </MainLayout>
  );
};
export default Settings;
