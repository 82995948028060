import React, { useContext } from 'react';
import AuthContext from '../../../contexts/AuthContext';
import DoctorEditInformation from '../../../components/DoctorEditInformation';
import PatientEditInformation from '../../../components/PatientEditInformation';
import HomeCard from '../../../components/HomeCard';
import Edit from '../../../assets/images/edit.png';

import './Profile.css';
import AdminEditInformation from '../../../components/AdminEditInformation';

function Profile() {
  const { typeOfUser } = useContext(AuthContext);
  return (
    <HomeCard
      title="Edit your profile"
      picture={Edit}
      showAvatar
      customClass="profileCard"
    >
      {typeOfUser === 'ADMIN' && <AdminEditInformation />}
      {typeOfUser === 'DOCTOR' && <DoctorEditInformation />}
      {typeOfUser === 'PATIENT' && <PatientEditInformation />}
    </HomeCard>
  );
}

export default Profile;
